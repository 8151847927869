export const platformSettingsData = [
    {
      title: "account",
      options: [
        {
          checked: true,
          label: "Email me when someone follows me",
        },
        {
          checked: false,
          label: "Email me when someone answers on my post",
        },
        {
          checked: true,
          label: "Email me when someone mentions me",
        },
      ],
    },
    {
      title: "application",
      options: [
        {
          checked: false,
          label: "New launches and projects",
        },
        {
          checked: true,
          label: "Monthly product updates",
        },
        {
          checked: false,
          label: "Subscribe to newsletter",
        },
      ],
    },
  ];
  
export default platformSettingsData;