import { useSelector } from "react-redux"
import { selectCurrentUser, selectCurrentToken, selectCurrentCompany } from "./authSlice"
import { Link } from "react-router-dom"

import DefaultLayout from '../../layouts/DefaultLayout';
import Breadcrumb from '../../components/Breadcrumb';

const Welcome = () => {
    const user = useSelector(selectCurrentUser)
    const company = useSelector(selectCurrentCompany)

    const welcome = user ? `Welcome ${user}!` : 'Welcome!'

    const content = (
        <DefaultLayout>
        <Breadcrumb pageName='Welcome' />
        <section className="welcome">
            <h1>{welcome}</h1>
            <p>Company: {company}</p>
            <p><Link to="/dashboard/home">Go to my dashboard</Link></p>
        </section>
        </DefaultLayout>
    )

    return content
}
export default Welcome