import React, {useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LoginPattern from "../../assets/images/patterns/login_pattern.avif"
import { useDispatch } from "react-redux";
import { setCredentials } from "../../features/auth/authSlice";
import { useLoginMutation } from "../../features/auth/authApiSlice";
import usePersist from "../../hooks/usePersist";
import useAuth from "../../hooks/useAuth";
import PulseLoader from 'react-spinners/PulseLoader'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    Input,
    Button,
    Typography,
} from "@material-tailwind/react";


export function LoginPage() {

    const { userStatus, isAdmin, isClient, isVendor } = useAuth();

    const userRef = useRef(null);
    const errRef = useRef();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [persist, setPersist] = usePersist();

    const navigate =  useNavigate();
    const dispatch = useDispatch();

    // API Login Mutation
    const [login, { isLoading }] = useLoginMutation()

    const showAlertMessage = (message) => {
        toast.error(message, {
          position: 'top-right',
        });
    };
    
    useEffect(() => {
        userRef.current?.focus()
    }, [userRef])

    useEffect(() => {
        setErrMsg('');
    }, [username, password])

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Login from LoginMutation
        try {
            const userData = await login({username, password}).unwrap();
            console.log("LoginPage:userData="+JSON.stringify(userData));

            dispatch(setCredentials({ ...userData, username }))
            setUsername("");
            setPassword("");
            navigate('/welcome')
        } catch (err) {
            if (!err.status) {
                showAlertMessage('No server response');
            } else if (err.status === 400) {
                showAlertMessage('Missing Username or Password');
            } else if (err.status === 401) {
                showAlertMessage('Unauthorized! '+err?.data.message);
            } else if (err.status === 404) {
                showAlertMessage('Not Found');
            } else {
                console.log('error='+JSON.stringify(err));
                let message = err?.data?.message ? 'Login Failed' : 'Could not login, Please call customer support!'
                showAlertMessage(message);
            }
            errRef.current?.focus();
            console.error("ERROR"+JSON.stringify(err));
        }
    }

    const handleUserInput = ({ target }) => setUsername(target.value);
    const handlePwdInput = ({ target }) => setPassword(target.value);
    const handleToggle = () => setPersist(prev => !prev);

    const errClass = errMsg ? "errmsg" : "offscreen"

    if (isLoading) return <PulseLoader color={"#FFF"} />

    return (
        <div className="relative flex h-screen content-center items-center justify-center pt-48 pb-32">
        <section className="m-8 flex gap-4">
        <p ref={errRef} className={errClass} aria-live="assertive">{errMsg}</p>
        <div className="w-full lg:w-3/5 mt-24">
            <div className="text-center">
                <Typography variant="h2" className="font-bold mb-4">Log into your account</Typography>
                <Typography variant="paragraph" color="blue-gray" className="text-lg font-normal">Enter your username and password to Login.</Typography>
            </div>
            <form className="mt-8 mb-2 mx-auto w-80 max-w-screen-lg lg:w-1/2">
            <div className="mb-1 flex flex-col gap-6">
                <Typography variant="small" color="blue-gray" className="-mb-3 font-medium">
                    Username
                </Typography>
                <Input
                    size="lg"
                    type="text"
                    placeholder="username"
                    value={username}
                    onChange={handleUserInput}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                />
                <Typography variant="small" color="blue-gray" className="-mb-3 font-medium">
                    Password
                </Typography>
                <Input
                    type="password"
                    size="lg"
                    placeholder="********"
                    value={password}
                    onChange={handlePwdInput}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                />
            </div>
            <Button 
                className="mt-6"
                type="submit"
                fullWidth
                color={username && password ? "gray" : "blue-gray"}
                disabled={!(username && password)}
                onClick={handleSubmit}
            >
                Login
            </Button>

            <div className="flex items-center justify-center gap-2 mt-6">
                <Typography variant="small" className="font-medium text-gray-900">
                    <a href="/forgot-password">
                        Forgot Password
                    </a>
                    </Typography>
            </div>

            <div className="flex items-center justify-center gap-2 mt-6">
            <Typography variant="small" className="font-medium text-gray-900">
            <label htmlFor="persist" className="form__persist">
                <input
                    type="checkbox"
                    className="form__checkbox"
                    id="persist"
                    onChange={handleToggle}
                    checked={persist}
                />
                  Trust This Device
            </label>
            </Typography>
            </div>

            </form>

        </div>
        <div className="w-2/5 h-full hidden lg:block">
            <img
                alt="pattern"
                src={LoginPattern}
                className="h-full w-full object-cover rounded-3xl"
            />
        </div>

        </section>
        </div>
    );
}

export default LoginPage;