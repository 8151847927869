export const conversationsData = [
    {
      img: "/img/team-2.jpg",
      name: "Sophie B.",
      message: "Hi! Update on project A",
    },
    {
      img: "/img/team-1a.jpg",
      name: "Alexander",
      message: "Awesome work, Project B needs documentation update",
    },
    {
      img: "/img/team-2a.jpg",
      name: "Ivanna",
      message: "Is this what was agreed during requirements call.",
    },
    {
      img: "/img/team-3.jpg",
      name: "Peterson",
      message: "Need to update and send project close out documents.",
    },
  ];
  
export default conversationsData;