export const projectsData = [
    {
      img: "/img/home-decor-1.jpeg",
      title: "Project 1111",
      tag: "Project #1",
      description:
        "Build an app to connect users in different geographical locations via video",
      route: "/dashboard/profile",
      members: [
        { img: "/img/team-1a.jpg", name: "Team Member 1" },
        { img: "/img/team-2.jpg", name: "Team Member 2" },
        { img: "/img/team-3.jpg", name: "Team Member 3" },
        { img: "/img/team-4.png", name: "Team Member 4" },
      ],
    },
    {
      img: "/img/home-decor-2.jpeg",
      title: "Project 2411",
      tag: "Project #2",
      description:
        "Home decor company partners to build and maintain website/SEO",
      route: "/dashboard/profile",
      members: [
        { img: "/img/team-1a.jpg", name: "Team Member 1" },
        { img: "/img/team-2.jpg", name: "Team Member 2" },
        { img: "/img/team-3.jpg", name: "Team Member 3" },
        { img: "/img/team-4.png", name: "Team Member 4" },
      ],
    },
    {
      img: "/img/home-decor-3.jpeg",
      title: "Project 3211",
      tag: "Project #3",
      description:
        "Online home furnishing store: website, app and maintenance",
      route: "/dashboard/profile",
      members: [
        { img: "/img/team-1a.jpg", name: "Team Member 1" },
        { img: "/img/team-2.jpg", name: "Team Member 2" },
        { img: "/img/team-3.jpg", name: "Team Member 3" },
        { img: "/img/team-4.png", name: "Team Member 4" },
      ],
    },
    {
      img: "/img/home-decor-4.jpeg",
      title: "Project 1214",
      tag: "Project #4",
      description:
        "Photography Studio for Rent to amateur and professionals by the hour: Prod-Market Fit",
      route: "/dashboard/profile",
      members: [
        { img: "/img/team-1a.jpg", name: "Team Member 1" },
        { img: "/img/team-2.jpg", name: "Team Member 2" },
        { img: "/img/team-3.jpg", name: "Team Member 3" },
        { img: "/img/team-4.png", name: "Team Member 4" },
      ],
    },
  ];
  
export default projectsData;