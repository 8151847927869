import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice"

const employeesAdapter = createEntityAdapter({})

const initialState = employeesAdapter.getInitialState()

export const employeeApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getEmployee: builder.query({
            query: ({ id }) => ({
                url: `/admin/employee/${id}`
            })
        })
    })
})

export const employeesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getEmployees: builder.query({
            // note: an optional `queryFn` may be used in place of `query`
            query: () => ({
                url: '/admin/employees',
                header: {'content-type': 'application/json', 
                    'Access-Control-Allow-Origin': '*', 
                    'Access-Control-Allow-Credentials': "true"
                },
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            // Keep unused data for 30 seconds - cache in RTK query - default is 60 seconds
            keepUnusedDataFor: 30,
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: response => {
                 return response;
            },
            // Pick out errors and prevent nested properties in a hook or selector
            transformErrorResponse: (response, meta, arg) => response.status,
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Employee', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Employee', id }))
                    ]
                } else return [{ type: 'Employee', id: 'LIST' }]
            }
        }),
        addNewEmployee: builder.mutation({
            query: initialEmployeeData => ({
                url: '/admin/addemployee',
                    method: 'POST',
                    body: {
                        ...initialEmployeeData,
                    }
            }),
            transformResponse: response => {
                 return response;
            },
            invalidatesTags: [
                { type: 'Employee', id: "LIST" }
            ]
        }),
        updateEmployee: builder.mutation({
            query: initialEmployee => ({
                url: '/admin/employees',
                method: 'PATCH',
                body: {
                    ...initialEmployee,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Employee', id: arg.id }
            ]
        }),
        deleteEmployee: builder.mutation({
            query: ({ id }) => ({
                url: `/admin/employees/${id}`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Employee', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetEmployeesQuery,
    useAddNewEmployeeMutation,
    useUpdateEmployeeMutation,
    useDeleteEmployeeMutation,
} = employeesApiSlice

// returns the query result object
export const selectEmployeesResult = employeesApiSlice.endpoints.getEmployees.select()

// creates memoized selector
const selectEmployeesData = createSelector(
    selectEmployeesResult,
    employeesResult => employeesResult.data // normalized state object with ids & entities
)

// getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllEmployees,
    selectById: selectEmployeeById,
    selectIds: selectEmployeeIds
} = employeesAdapter.getSelectors(state => selectEmployeesData(state) ?? initialState)