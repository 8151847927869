import React from "react"

// @material-tailwind/react
import {
  Chip,
  Avatar,
  Button,
  Typography,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Checkbox,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  IconButton,
  Progress
} from "@material-tailwind/react"

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline"
import {
  AdjustmentsVerticalIcon,
  EllipsisHorizontalIcon
} from "@heroicons/react/24/solid"

import DefaultLayout from '../../layouts/DefaultLayout';
import Breadcrumb from '../../components/Breadcrumb';
import { useNavigate } from "react-router";
import { useGetProjectStepsQuery } from "../../features/projects/projectStepsApiSlice";
import { useParams } from 'react-router-dom';


//logo, title, date, team, value, color

const TABLE_HEAD = [
  "Description",
  "Team",
  "Progress",
  "Document Link",
  "Due Date",
]

const ProjectDetails = () => {

  const { project_id } = useParams();

  const navigate = useNavigate();

  const handleProjectsClick = (e) => {
    e.preventDefault(); // so page doesn't reload when submitting
    navigate("/dashboard/projects");
  }

  const handleAddProjectStep = (e) => {
    e.preventDefault(); // so page doesn't reload when submitting
    navigate(`/auth/addprojectstep/${project_id}`);
  }

  const { data: project_steps, loading, error } = useGetProjectStepsQuery(project_id);

  return (
    <DefaultLayout>
      <Breadcrumb pageName='Projects Tables' />
      <section className="m-10">
        <Card className="h-full w-full">
          <CardHeader
            floated={false}
            shadow={false}
            className="rounded-none flex flex-wrap gap-4 justify-between p-2"
          >
            <div>
              <Typography variant="h6" color="blue-gray">
                Projects Table
              </Typography>
            </div>
            <div className="flex items-center gap-4">
              <Button
                color="gray"
                variant="outlined"
                className="flex items-center gap-1"
              >
                filter
                <AdjustmentsVerticalIcon className="w-4 h-4 text-gray-900" />
              </Button>
              <Button color="gray" onClick={handleProjectsClick}>Projects</Button>
              <Button color="gray" onClick={handleAddProjectStep}>New Step</Button>
            </div>
          </CardHeader>
          <CardBody className="overflow-scroll !px-0 py-0">
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map(head => (
                    <th key={head} className="border-b border-gray-300 !p-6">
                      <Typography
                        color="blue-gray"
                        variant="small"
                        className="!font-bold"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {project_steps?.map(
                  ({ end_date, project_id, step_desc, team, step_id, start_date, step_document, progress }) => {
                    const classes = "!p-4 border-b border-gray-300"
                    return (
                      <tr key={step_desc}>
                        <td className={classes}>
                          <div className="flex items-center gap-2">
                            <Checkbox />
                            <img className="w-5 h-5" src="https://www.material-tailwind.com/img/logos/figma.svg" alt="" />
                            <Typography
                              variant="small"
                              className="font-bold"
                              color="blue-gray"
                            >
                              {step_desc}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="w-max">
                            <Chip color="gray" variant="ghost" value={team} />
                          </div>
                        </td>
                        <td className={classes}>
                          <Typography
                            color="blue-gray"
                            className="text-xs !font-semibold"
                          >
                            {progress}%
                          </Typography>
                          <Progress value={progress} color={progress} />
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            className="!font-normal text-gray-600"
                          >
                            {step_document}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            className="!font-normal text-gray-600"
                          >
                            {end_date}
                          </Typography>
                        </td>
                        <td className="border-b border-gray-300 text-right pr-6">
                          <Menu>
                            <MenuHandler>
                              <IconButton variant="text">
                                <EllipsisHorizontalIcon className="w-8 h-8 text-gray-600" />
                              </IconButton>
                            </MenuHandler>
                            <MenuList>
                              <MenuItem>Edit</MenuItem>
                              <MenuItem>Update</MenuItem>
                              <MenuItem>Delete</MenuItem>
                            </MenuList>
                          </Menu>
                        </td>
                      </tr>
                    )
                  }
                )}
              </tbody>
            </table>
          </CardBody>
          <CardFooter className="flex justify-between items-center">
            <Typography variant="h6" color="blue-gray">
              Page 2 <span className="font-normal text-gray-600">of 10</span>
            </Typography>
            <div className="flex gap-4">
              <Button
                variant="outlined"
                size="sm"
                className="flex items-center gap-1"
              >
                <ChevronLeftIcon strokeWidth={3} className="h-3 w-3" />
                prev
              </Button>
              <Button variant="outlined" className="flex items-center gap-1">
                next
                <ChevronRightIcon strokeWidth={3} className="h-3 w-3" />
              </Button>
            </div>
          </CardFooter>
        </Card>
      </section>
    </DefaultLayout>
  )
}

export default ProjectDetails
