import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice"

const projectStepsAdapter = createEntityAdapter({})

const initialState = projectStepsAdapter.getInitialState()

export const projectStepsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProjectSteps: builder.query({
            // note: an optional `queryFn` may be used in place of `query`
            query: (id) => ({
                url: `/admin/project_steps/${id}`,
                header: {'content-type': 'application/json', 
                    'Access-Control-Allow-Origin': '*', 
                    'Access-Control-Allow-Credentials': "true"
                },
                validateStatus: (response, result) => {
                    return response.status === 200
                },
            }),
            // Keep unused data for 5 seconds - cache in RTK query - default is 60 seconds
            keepUnusedDataFor: 5,
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: response => {
                 return response;
            },
            // Pick out errors and prevent nested properties in a hook or selector
            transformErrorResponse: (response, meta, arg) => response.status,
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'ProjectSteps', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'ProjectSteps', id }))
                    ]
                } else return [{ type: 'ProjectSteps', id: 'LIST' }]
            }
        }),
        addNewProjectStep: builder.mutation({
            query: initialProjectStepData => ({
                url: '/admin/project_steps',
                    method: 'POST',
                    body: {
                        ...initialProjectStepData,
                    }
            }),
            transformResponse: response => {
                 return response;
            },
            invalidatesTags: [
                { type: 'ProjectSteps', id: "LIST" }
            ]
        }),
        addNewProjectStepDocument: builder.mutation({
             query: (new_document) => ({
                url: '/admin/upload_project_steps',
                method: 'POST',
                body: new_document,
            }),
            transformResponse: response => {
                 return response;
            },
            invalidatesTags: [
                { type: 'ProjectSteps', id: "LIST" }
            ]
        }),
        updateProjectStep: builder.mutation({
            query: initialProjectStepData => ({
                url: '/admin/project_steps',
                method: 'PATCH',
                body: {
                    ...initialProjectStepData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Project', id: arg.id }
            ]
        }),
        deleteProjectStep: builder.mutation({
            query: ({ id }) => ({
                url: `/admin/project_steps`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'ProjectSteps', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetProjectStepsQuery,
    useAddNewProjectStepMutation,
    useAddNewProjectStepDocumentMutation,
    useUpdateProjectStepMutation,
    useDeleteProjectStepMutation,
} = projectStepsApiSlice

export const selectProjectStepsResult = projectStepsApiSlice.endpoints.getProjectSteps.select()

// creates memoized selector
const selectProjectStepsData = createSelector(
    selectProjectStepsResult,
    projectStepsResult => projectStepsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllProjectSteps,
    selectById: selectProjectStepsById,
    selectIds: selectProjectStepsIds
    // Pass in a selector that returns the Projects slice of state
} = projectStepsAdapter.getSelectors(state => selectProjectStepsData(state) ?? initialState)