import React, { useState } from "react";
import { MagnifyingGlassIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { UserPlusIcon } from "@heroicons/react/24/solid";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";

import { useGetUsersQuery } from "../../../features/users/usersApiSlice"

import DefaultLayout from '../../../layouts/DefaultLayout';
import Breadcrumb from '../../../components/Breadcrumb';
import { EditUserForm } from "./EditUserForm";
import RegisterUser from "./RegisterUser";

// TODO: move to constants
const TABS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Registered",
    value: "registered",
  },
  {
    label: "Pending",
    value: "pending",
  },
];

// TODO: move to constants
const TABLE_HEAD = ["Client Name", "Sector", "Registered", "Phone", "", ""];
 
 
export function UsersTable() {

  const {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetUsersQuery('usersTable', {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })

  const navigate =  useNavigate();

  const [activeTab, setTab] = useState("all");
  

  const handleSubmit = (e) => {
    e.preventDefault(); // so page doesn't reload when submitting

    navigate("/auth/adduser")
    
  }

  let content;

  if (isLoading) {
    content = <p>"Loading..."</p>;
  } else if (isSuccess) {
    content = (
      <DefaultLayout>
      <Breadcrumb pageName='View Users' />
      <Card className="h-full w-full">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-8 flex items-center justify-between gap-8">
            <div>
              <Typography variant="h5" color="blue-gray">
                Users list
              </Typography>
              <Typography color="gray" className="mt-1 font-normal">
                See information about all users
              </Typography>
            </div>
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              <Button variant="outlined" size="sm">
                view all
              </Button>
              <Button 
                className="flex items-center gap-3" 
                size="sm"
                onClick={handleSubmit}
              >
                <UserPlusIcon strokeWidth={2} className="h-4 w-4" /> Add User
              </Button>
            </div>
          </div>
          <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
            <Tabs value="all" className="w-full md:w-max">
              <TabsHeader>
                {TABS.map(({ label, value }) => (
                  <Tab key={value} value={value} onClick={(e) => {setTab(value)}}>
                    &nbsp;&nbsp;{label}&nbsp;&nbsp;
                  </Tab>
                ))}
              </TabsHeader>
            </Tabs>
            <div className="w-full md:w-72">
              <Input
                label="Search"
                icon={<MagnifyingGlassIcon className="h-5 w-5" />}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody className="overflow-scroll px-0">
          
          <table className="mt-4 w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head, index) => (
                  <th
                    key={head}
                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}{" "}
                      {index !== TABLE_HEAD.length - 1 && (
                        <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                      )}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {users.map(            
                ({ identity, uname, email, phone, user_type, sector, pending, fname, lname }, index) => {
                  const isLast = index === users.length - 1;
                  const classes = isLast
                    ? "p-4"
                    : "p-4 border-b border-blue-gray-50";
  
                  return (
                    <tr key={email} hidden={(activeTab ==="registered" && pending === "Y") || (activeTab ==="pending" && pending === "N" )}> {/* email is unique */}
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          {/*<Avatar src={img} alt={name} size="sm" />*/}
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {uname}
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal opacity-70"
                            >
                              {email}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {user_type}
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal opacity-70"
                          >
                            {sector}
                          </Typography>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="w-max">
                          <Chip
                            variant="ghost"
                            size="sm"
                            value={pending === "N" ? "registered" : "pending"}
                            color={pending === "N" ? "green" : "blue-gray"}
                          />
                        </div>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                        >
                          {phone}
                        </Typography>
                      </td>
                      <td className="border-b border-gray-300 text-right pr-6">
                        {/* Modal toggle */}
                        <EditUserForm identity={identity} uname={uname} email={email} phone={phone} user_type={user_type} sector={sector} pending={pending} fname={fname} lname={lname} />
                      </td>
                      <td className="border-b border-gray-300 text-right pr-6">
                        <RegisterUser identity={identity} email={email} pending={pending} />
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>

        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page 1 of 10
          </Typography>
          <div className="flex gap-2">
            <Button variant="outlined" size="sm">
              Previous
            </Button>
            <Button variant="outlined" size="sm">
              Next
            </Button>
          </div>
        </CardFooter>
      </Card>
      </DefaultLayout>
    )
  } else if (isError) {
    content = <p>{JSON.stringify(error)}</p>;
  }

  return content
}