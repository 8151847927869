import {
  Typography,
  Card,
  CardBody
} from "@material-tailwind/react";

import { Footer } from "../../widgets/layout/footer";

const TermsPage = () => {

  return (

    <>
      <div className="relative flex content-center items-center justify-center pt-16 pb-32"> {/* pt-48 pb-16 */}
        {/* The next 2 divs are for background coloring - consistency across pages */}
        <div className="absolute top-0 h-full w-full bg-cover bg-center bg-blue-100" />
        <div className="absolute top-0 h-full w-full bg-black/60 bg-cover bg-center" />
        <div className="max-w-8xl container relative mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="ml-auto mr-auto w-full px-4 text-left">
              <Card className="mt-6 w-full">
                <CardBody>
                  <Typography
                    color="white"
                    className="mb-6 text-black"
                  >
                    <section className="mb-6">
                      <h2 className="font-semibold mb-2 text-xl">Introduction</h2>
                      <p>
                        Welcome to <a href="./" className="text-blue-600 font-semibold">XConnnections.co.</a>  By accessing or using our website and services, you agree to comply with and be bound by these Terms and Conditions. Please read the terms and conditions before you start to use this site.
                        XConnections Co. referred hereto as "<strong>we</strong>", "<strong>us</strong> " or "<strong>xconnections</strong>" and "<strong>you</strong>" or "<strong>user</strong>" means you as a user of this site and services of the platform.
                      </p>
                    </section>

                    <section className="mb-6">
                      <h2 className="font-semibold mb-2 text-xl">Key Terms</h2>
                      <p><strong className="font-semibold"> Client:</strong> A user or company that wishes to avail services of a specialized firm to build a product or service.</p>
                      <p><strong className="font-semibold"> Vendor:</strong> A company that specializes in building a product or providing services to fulfill a client need. </p>
                    </section>


                    <section className="mb-6">
                      <h2 className="font-semibold mb-2 text-xl">Eligibility and Account Registration</h2>
                      <p><strong className="font-semibold"> 1. Eligibility:</strong> You must be at least 18 years old to use our services. By using this site, you represent and warrant that you meet this requirement.</p>
                      <p><strong className="font-semibold"> 2. Account Registration:</strong> To use certain features of our Service, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>
                      <p><strong className="font-semibold">3. Account Security:</strong> You are responsible for maintaining the confidentiality of your account login information and are fully responsible for all activities that occur under your account.</p>
                    </section>

                    <section className="mb-6">
                      <h2 className="font-semibold mb-2 text-xl">User Obligations and Prohibited Activities</h2>
                      <p><strong className="font-semibold">1. Compliance:</strong> You agree to comply with all applicable laws and regulations when using our Service.</p>
                      <p><strong className="font-semibold">2. Prohibited Activities:</strong> You agree not to:</p>
                      <ul className="list-disc list-inside ml-4">
                        <li>Violate any laws or regulations.</li>
                        <li>Infringe the rights of any third party.</li>
                        <li>Use the Service for any unauthorized commercial purposes.</li>
                        <li>Engage in fraudulent, harmful, or malicious activities.</li>
                        <li>Post or transmit any content that is offensive, defamatory, or otherwise objectionable.</li>
                      </ul>
                    </section>

                    <section className="mb-6">
                      <h2 className="text-xl font-semibold mb-2 ">Service Fees and Payment Terms</h2>
                      <p><strong className="font-semibold">1. Fees:</strong> Certain services on <a href="./" className="text-blue-600 font-semibold">XConnnections.co.</a> may be subject to fees. All fees are as specified on the Service at the time of purchase or engagement and are non-refundable unless otherwise stated.</p>
                      <p><strong className="font-semibold">2. Payment Processing:</strong> Payments for services will be processed through third-party payment processors. You agree to provide accurate and complete payment information and comply with the terms of such payment processors.</p>
                      <p><strong className="font-semibold">3. Value Added Services</strong> Certain services on the site will incur additional payments. These services can be added while finalizing the purchase and/or before the project is complete. Any changes to exsiting contract after completion will be considered a new engagement.</p>
                    </section>

                    <section className="mb-6">
                      <h2 className="text-xl font-semibold mb-2">User Content and Licensing</h2>
                      <p><strong className="font-semibold">1. Ownership:</strong> As a client you retain ownership of all content delivered as part of a project close out. As a vendor you relinquish all IP to the client paying for the services.XConnections retains the rights to documentation obtained during the course of the project with the understanding that this will not be shared with any other clients of XConnections</p>
                      <p><strong className="font-semibold">2. License:</strong> By submitting, posting, or displaying content on or through the Service, you grant us a worldwide, non-exclusive, royalty-free license to discuss certain aspects of the project with new prospective clients. These details shall not include the financial or client Intellectual property.
                        XConnections is allowed to use a high level overview of the project and the technology implementation without revealing any details that could lead to a breach of contract. </p>
                      <p><strong className="font-semibold">3. Content Restrictions:</strong> You agree not to post content that infringes the intellectual property rights of others or is illegal, offensive, or inappropriate.</p>
                    </section>

                    <section className="mb-6">
                      <h2 className="text-xl font-semibold mb-2">Intellectual Property</h2>
                      <p><strong className="font-semibold">1. Our IP:</strong> All intellectual property rights in and to the Service, including but not limited to software, text, graphics, logos, and designs, are owned by or licensed to Xconnnections.co.</p>
                      <p><strong className="font-semibold">2. User Restrictions:</strong> You agree not to reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, or otherwise use the intellectual property of Xconnnections.co without our prior written consent.</p>
                    </section>

                    <section className="mb-6">
                      <h2 className="text-xl font-semibold mb-2">Limitation of Liability</h2>
                      <p><strong className="font-semibold">1. Disclaimer:</strong> The Service is provided on an "as is" and "as available" basis. We make no warranties, express or implied, regarding the Service.</p>
                      <p><strong className="font-semibold">2. Limitation:</strong> To the fullest extent permitted by law, Xconnnections.co shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly.</p>
                    </section>

                    <section className="mb-6">
                      <h2 className="text-xl font-semibold mb-2">Termination and Suspension</h2>
                      <p><strong className="font-semibold">1. Termination:</strong> We may terminate or suspend your account and access to the Service at any time, without prior notice or liability, for any reason, including if you breach these Terms.</p>
                      <p><strong className="font-semibold">2. Effect of Termination:</strong> Upon termination, your right to use the Service will immediately cease. All provisions of the Terms which by their nature should survive termination shall survive termination, including ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>
                    </section>

                    <section className="mb-6">
                      <h2 className="text-xl font-semibold mb-2">Dispute Resolution</h2>
                      <p><strong className="font-semibold">1. Governing Law:</strong> These Terms shall be governed and construed in accordance with the laws of the jurisdiction in which Xconnnections.co operates, without regard to its conflict of law provisions.</p>
                      <p><strong className="font-semibold">2. Arbitration:</strong> Any disputes arising out of or relating to these Terms or the Service will be resolved through binding arbitration in accordance with the rules of the American Arbitration Association.</p>
                    </section>

                    <section>
                      <h2 className="text-xl font-semibold mb-2">Miscellaneous</h2>
                      <p><strong className="font-semibold">1. Changes to Terms:</strong> We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on our website. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms.</p>
                      <p><strong className="font-semibold">2. Contact Information:</strong> If you have any questions about these Terms, please contact us at <a href="mailto:info@xconnnections.co" className="text-blue-600 font-semibold">support@xconnnections.co</a>.</p>
                    </section>

                  </Typography>

                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>

  );
};

export default TermsPage;