import {
  BriefcaseIcon,
  ChartBarIcon,
  PlayIcon,
  PuzzlePieceIcon,
} from "@heroicons/react/24/solid";

var partner_description = `We prioritize building long-term relationships with our clients, ensuring that their needs are not only met
but exceeded at every step of the journey.`

export const contactData = [
  {
    title: "Build Together Mentality" ,
    icon: PuzzlePieceIcon,
    description :"XConnections is dedicated to revolutionizing how \"small\" tech companies connect with service vendors, simplifying the process for seamless collaboration.."
  },
  {
    title: "Exceptional Service",
    icon: BriefcaseIcon,
    description:
      "Services for each project are tailored to the needs of the clients and vendor partners.",
  },
  {
    title: "Partnership",
    icon: ChartBarIcon,
    description: partner_description
  },
  {
    title: "Time Savings",
    icon: PlayIcon,
    description:
      "Reduce the Time to Market by avoiding rework. Measure twice, cut once approach to building a product.",
  },
];

export default contactData;