import React, {useState } from "react";
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import {
    Input,
    Checkbox,
    Button,
    Typography,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import ForgotPasswordLogo from "../../assets/images/logos/XConnections_logo2.png"
import { useForgotPasswordUserMutation } from "../../features/auth/authApiSlice";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const showAlertMessage = (message) => {
    toast.error(message, {
        position: 'top-right',
    });
};

const showConfirmationMessage = (message) => {
    toast.success(message, {
        position: 'top-left',
    });
};

export function ForgotPassword() {

    const [email, setEmail] = useState("");

    const navigate = useNavigate();

    //const params = useParams();

    //const userHash = params.hashId;
    //console.log("ForgotPassword:userHash: ", JSON.stringify(userHash))

    const [forgotPasswordUser, { token, userEmail }] = useForgotPasswordUserMutation();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
          const response = await forgotPasswordUser({email: email}).unwrap();
          console.log('Password Reset user:', response.message);
          showConfirmationMessage("Password reset link has been sent!")
          // TODO: after submission, bring user to page that shows:
          // An email has been sent to <email> with a link that will allow you to 
          // reset your password.
          navigate('/login')
        } catch (error) {
          console.error("error=", JSON.stringify(error));
          console.error('Failed to activate user: ', error?.data.message);
          // NOTE: transformErroResponse only returns status
          // console.error("Failed to activate user: ", error)
          showAlertMessage("You are not registered!")
        }
    }
  
    const handleEmailInput = ({ target }) => setEmail(target.value);


    return (
        <div className="relative flex h-screen content-center items-center justify-center pt-16 pb-32">
        <section className="m-8 flex">
        <div className="w-2/5 h-full hidden lg:block">
            <img
            alt="logo"
            src={ForgotPasswordLogo}
            className="h-full w-full object-cover rounded-3xl"
            />
        </div>
        <div className="w-full lg:w-3/5 flex flex-col items-center flex-wrap justify-center">
                <Typography variant="small" color="blue-gray" className="flex-wrap">
                  Enter your email address associated with your account 
                  and we will send you a link to reset your password.
                </Typography>
            <form className="mt-8 mb-2 mx-auto w-80 max-w-screen-lg lg:w-1/2">
            <div className="mb-1 flex flex-col gap-6">
                <Typography variant="small" color="blue-gray" className="-mb-3 font-medium">
                    Email
                </Typography>
                <Input
                    size="lg"
                    type="text"
                    placeholder="name@email.com"
                    value={email}
                    onChange={handleEmailInput}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                />
            </div>
            <Button 
                className="mt-6" 
                fullWidth
                color={email ? "gray" : "blue-gray"}
                disabled={!(email)}
                onClick={handleSubmit}
            >            
                Continue
            </Button>
            <Typography variant="paragraph" className="text-center text-blue-gray-500 font-medium mt-4">
                Already a trusted partner?
                <Link to="/login" className="text-gray-900 ml-1">Login</Link>
            </Typography>
            </form>
        </div>
        </section>
        </div>
);
}

export default ForgotPassword;