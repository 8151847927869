import React, { useState, useEffect } from 'react'
import { Routes, Route, useLocation } from "react-router-dom";
import { Navbar } from "./widgets/layout/navbar";
import routes from "./routes";

import Layout from "./components/Layout";

// Home related pages
import { HomePage } from "./pages/home/HomePage";
import TermsPage from "./pages/home/TermsPage"
import ReadMePage from "./pages/home/ReadMePage"
import Welcome from './features/auth/Welcome'

// Auth related pages
import { LoginPage } from "./pages/auth/LoginPage";
import { ActivationPage } from "./pages/auth/ActivationPage";
import UnauthorizedPage from "./pages/auth/UnauthorizedPage";
import ErrorsPage from "./pages/auth/ErrorPage";
// Admin relaated pages
import CompleteRegistration from "./pages/auth/admin/CompleteRegistration";
import AddUserForm from "./pages/auth/admin/AddUserForm";
import AddCompanyForm from "./pages/auth/admin/AddCompanyForm"
import { UsersTable } from "./pages/auth/admin/ViewUsers";
import UserTable from "./pages/auth/admin/UserTable";
import { CompaniesTable } from "./pages/auth/admin/ViewCompanies"
import AddProjectForm from "./pages/auth/admin/AddProjectForm"

import PageTitle from "./components/PageTitle";
import Calendar from "./pages/Calendar";
import Settings from "./pages/Settings";
import Alerts from "./pages/uielements/Alerts";

// Client related pages
// import ClientRegistrationForm from "./pages/auth/client/PendingRegisterClient.tsx"

// Vendor related pages
// import VendorRegistrationForm from "./pages/auth/vendor/PendingRegisterVendor.tsx"

// Dashboard related pages
import { DashboardHome } from "./pages/dashboard/home";
import { Profile } from "./pages/dashboard/profile";
import Analytics from "./pages/dashboard/analytics";
import ProjectDetails from "./pages/dashboard/projectdetails";
import ProjectCards from "./pages/dashboard/projectcards";
import Messages from './pages/uielements/Messages';

import Prefetch from './features/auth/Prefetch'
import PersistLogin from './features/auth/PersistLogin'
import RequireAuth from './features/auth/RequireAuth'
import { ROLES } from './constants/roles'
import AddEmployeeForm from './pages/auth/admin/AddEmployee';
import AddProjectStepForm from './pages/auth/admin/AddProjectStep';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import AddCalendarEntry from './pages/auth/admin/AddCalendarEvent';


function App() {
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true)

  const preloader = document.getElementById('preloader')

  if (preloader) {
    setTimeout(() => {
      preloader.style.display = 'none'
      setLoading(false)
    }, 2000);
  }

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000)
  }, [])

  return (
    !loading && (
      <>
        {!(pathname.includes('/settings') || pathname.includes('/auth') || pathname.includes('/forgot') ||
          pathname.includes('/calendar') || pathname.includes('/messages') ||
          pathname.includes('/alerts') || pathname.includes('/dashboard') || pathname.includes('/welcome')) && (
            <div className="sticky top-0 z-999 flex w-full bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none">
              <Navbar routes={routes} />
            </div>
          )
        }

        <Routes>
          <Route path='*' element={<ErrorsPage />} />
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            {/*<Route path="*" element={<Navigate to="/" replace />} errorElement={<ErrorPage />}/>*/}

            {/* Public routes */}
            <Route path="login" element={<LoginPage />} />
            <Route path="/auth/activation/user/:hashId" element={<ActivationPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="readme" element={<ReadMePage />} />
            <Route path="terms" element={<TermsPage />} />

            {/* Protected Routes */}
            <Route element={<PersistLogin />}>

              <Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />} >
                <Route path="welcome" element={<Welcome />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}>
                <Route element={<Prefetch />}>
                  <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Client, ROLES.Vendor]} />}>
                    <Route path="dashboard">
                      <Route path="/dashboard/home" element={<DashboardHome />} />
                      <Route path="/dashboard/profile" element={<Profile />} />
                      <Route path="/dashboard/analytics" element={<Analytics />} />
                      <Route path="/dashboard/projectdetails/:project_id" element={<ProjectDetails />} />
                      <Route path="/dashboard/projects" element={<ProjectCards />} />
                    </Route>
                  </Route>

                  {/* Client only routes */}
                  <Route element={<RequireAuth allowedRoles={[ROLES.Client]} />}>
                    {/*<Route path="/client/registration" element={<ClientRegistrationForm />} />*/}
                    <Route path="dashboard">
                      <Route path="/dashboard/client" element={<DashboardHome />} />
                    </Route>
                  </Route>

                  {/* Vendor only routes */}
                  <Route element={<RequireAuth allowedRoles={[ROLES.Vendor]} />}>
                    {/*<Route path="/vendor/registration" element={<VendorRegistrationForm />} />*/}
                    <Route path="dashboard">
                      <Route path="/dashboard/vendor" element={<DashboardHome />} />
                    </Route>
                  </Route>

                  <Route
                    path="/calendar"
                    element={
                      <>
                        <PageTitle title="Calendar | XConnections" />
                        <Calendar />
                      </>
                    }
                  />

                  <Route
                    path="/settings"
                    element={
                      <>
                        <PageTitle title="Settings | XConnections" />
                        <Settings />
                      </>
                    }
                  />

                  <Route
                    path="/ui/alerts"
                    element={
                      <>
                        <PageTitle title="Alerts | XConnections" />
                        <Alerts />
                      </>
                    }
                  />

                  <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                    <Route
                      path="/ui/messages"
                      element={<Messages />}
                      errorElement={<ErrorsPage />}
                    />
                  </Route>

                  {/* Admin only routes */}
                  <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                    <Route path="auth">
                      <Route path="/auth/adduser" element={< AddUserForm />} errorElement={<UnauthorizedPage />} />
                      <Route path="/auth/addcompany" element={<AddCompanyForm />} errorElement={<UnauthorizedPage />} />
                      <Route path="/auth/viewusers" element={< UsersTable />} errorElement={<UnauthorizedPage />} />
                      <Route path="/auth/viewclients" element={< CompaniesTable />} errorElement={<UnauthorizedPage />} />
                      {/*<Route path="/auth/viewusers" element={< UserTable />} errorElement={<UnauthorizedPage />} />*/}
                      <Route path="/auth/dashboard/*" element={<DashboardHome />} errorElement={<UnauthorizedPage />} />
                      <Route path="/auth/register/:id" element={<CompleteRegistration />} errorElement={<UnauthorizedPage />} />
                      <Route path="/auth/addproject" element={<AddProjectForm />} errorElement={<UnauthorizedPage />} />
                      <Route path="/auth/addemployee" element={<AddEmployeeForm />} errorElement={<UnauthorizedPage />} />
                      <Route path="/auth/addprojectstep/:project_id" element={<AddProjectStepForm />} errorElement={<UnauthorizedPage />} />
                      <Route path="/auth/newmeeting" element={<AddCalendarEntry />} errorElement={<UnauthorizedPage />} />
                    </Route>
                  </Route>

                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </>
    )
  );
}

export default App;