import {
    HomeIcon,
    WifiIcon,
    UserCircleIcon,
    TableCellsIcon,
    BookOpenIcon,
    InformationCircleIcon,
    ServerStackIcon,
  } from "@heroicons/react/24/solid";
import { Profile } from "./pages/dashboard/profile";
import { Tables } from "./pages/dashboard/tables";
import ProjectDetails from "./pages/dashboard/projectdetails";
import { Notifications} from "./pages/dashboard/notifications";
import { UsersTable } from "./pages/auth/admin/ViewUsers";
import AddUserForm from "./pages/auth/admin/AddUserForm";
import CompleteRegistration from "./pages/auth/admin/CompleteRegistration";
import ReadMePage from "./pages/home/ReadMePage.jsx";
import HomePage from "./pages/home/HomePage.jsx";
import {DashboardHome} from "./pages/dashboard/home.jsx";
import TermsPage from "./pages/home/TermsPage.jsx";

const icon = {
className: "w-5 h-5 text-inherit",
};

export const routes = [
{
    layout: "home",
    pages: [
        {
            icon: <WifiIcon {...icon} />,
            name: "home",
            path: "/",
            element: <HomePage />,
            navVisibility: true,
            protectedPage: false
        },
        {
            name: "About Us",
            path: "/readme",
            element: <ReadMePage />,
            navVisibility: true,
            protectedPage: false
        },
        {
            name: "Terms",
            path: "/terms",
            element: <TermsPage />,
            navVisibility: true,
            protectedPage: false
        }
    ]
},
{
    layout: "dashboard",
    pages: [
    {
        icon: <HomeIcon {...icon} />,
        name: "dashboard",
        path: "/home",
        element: <DashboardHome />,
    },
    {
        icon: <UserCircleIcon {...icon} />,
        name: "profile",
        path: "/profile",
        element: <Profile />,
    },
    {
        icon: <TableCellsIcon {...icon} />,
        name: "tables",
        path: "/tables",
        element: <Tables />,
    },
    {
        icon: <BookOpenIcon {...icon} />,
        name: "projects",
        path: "/projects",
        element: <ProjectDetails />,
    },
    {
        icon: <InformationCircleIcon {...icon} />,
        name: "notifications",
        path: "/notifications",
        element: <Notifications />,
    },
    ],
},
{
    title: "admin pages",
    layout: "auth",
    pages: [
    {
        icon: <UserCircleIcon {...icon} />,
        name: "add user",
        path: "/adduser",
        element: <AddUserForm />
    },
    {
        icon: <ServerStackIcon {...icon} />,
        name: "user list",
        path: "/viewusers",
        element: <UsersTable />,
    },    
    {
        icon: <UserCircleIcon {...icon} />,
        name: "complete registration",
        path: "/register",
        element: <CompleteRegistration />
    },    
    ],
},
];

export default routes;