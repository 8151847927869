import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, Textarea, Typography, Checkbox } from "@material-tailwind/react";
import HeadQuarters from "../../assets/images/headquarters.png"
import { useAddNewContactMutation } from "../../features/contacts/contactsApiSlice"


export function XContactForm() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [actioned] = useState("N")
  const [id] = useState(0)
  const [termsChecked, settermscb] = useState(false);

  const [addNewContact, mutationFlags] = useAddNewContactMutation()

  const navigate = useNavigate()

  useEffect(() => {
    if (mutationFlags.isSuccess)
        setName('')
        setEmail('')
        setMessage('')
        navigate('/')
        window.scrollTo(0, 0)
  }, [mutationFlags.isSuccess, navigate])


  const canSave = [name, email, message].every(Boolean) && !mutationFlags.isLoading

  const onSaveContactClicked = async (e) => {
    e.preventDefault()
    if (canSave) {
        try {
        const returned = await addNewContact({ name: name, email, message, actioned, id })
        } catch (error) {
        console.error("error="+JSON.stringify(error))
        }
    }
  }

  const errClass = mutationFlags.isError ? "errmsg" : "offscreen"
  
    
  return (
    <>
    <p className={errClass}>{mutationFlags.error?.data?.message}</p>
    <section className="px-8 py-8 lg:py-16">
      <div className="container mx-auto text-center">
        <Typography
          variant="h1"
          color="blue-gray"
          className="mb-4 !text-3xl lg:!text-5xl"
        >
          Want to partner with us?
        </Typography>
        <Typography className="mb-10 font-normal !text-lg lg:mb-20 mx-auto max-w-3xl !text-gray-500">
          Complete this form and we will get back to you ASAP.
        </Typography>
        <div className="grid grid-cols-1 gap-x-12 gap-y-6 lg:grid-cols-2 items-start">
          <img
            src={HeadQuarters}
            alt="map"
            className="w-full h-full lg:max-h-[510px]"
          />
          <form
            action="#"
            className="flex flex-col gap-4 lg:max-w-sm"
          >
            <Typography
              variant="small"
              className="text-left !font-semibold !text-gray-600"
            >
              Select Options for Business Engagement
            </Typography>
            <div className="flex gap-4">
              <Button variant="outlined" className="max-w-fit">
                General inquiry
              </Button>
              <Button variant="outlined" className="max-w-fit">
                Product Support
              </Button>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Typography
                  variant="small"
                  className="mb-2 text-left font-medium !text-gray-900"
                >
                  Full Name
                </Typography>
                <Input
                  color="gray"
                  size="lg"
                  placeholder="Full Name"
                  name="full-name"
                  className="focus:border-t-gray-900"
                  containerProps={{
                    className: "!min-w-full",
                  }}
                  labelProps={{
                    className: "hidden",
                  }}
                  value={name}
                  onChange={(e) => {setName(e.target.value)}}
                />
              </div>
            </div>
            <div>
              <Typography
                variant="small"
                className="mb-2 text-left font-medium !text-gray-900"
              >
                Your Email
              </Typography>
              <Input
                color="gray"
                size="lg"
                placeholder="name@email.com"
                name="email"
                className="focus:border-t-gray-900"
                containerProps={{
                  className: "!min-w-full",
                }}
                labelProps={{
                  className: "hidden",
                }}
                value={email}
                onChange={(e) => {setEmail(e.target.value)}}
              />
            </div>
            <div>
              <Typography
                variant="small"
                className="mb-2 text-left font-medium !text-gray-900"
              >
                Your Message
              </Typography>
              <Textarea
                rows={6}
                color="gray"
                placeholder="Message"
                name="message"
                className="focus:border-t-gray-900"
                containerProps={{
                  className: "!min-w-full",
                }}
                labelProps={{
                  className: "hidden",
                }}
                value={message}
                onChange={(e) => {setMessage(e.target.value)}} 
              />
            </div>
            <Checkbox
              label={
                <Typography
                  variant="small"
                  color="gray"
                  className="flex items-center font-normal"
                >
                  I agree the
                  <a
                    href="/terms"
                    className="font-medium transition-colors hover:text-gray-900"
                  >
                    &nbsp;Terms and Conditions
                  </a>
                </Typography>
              }
              containerProps={{ className: "-ml-2.5" }} onChange={(e) => {settermscb(e.target.value)}}
            />
            <Button className="w-full" color="gray" onClick={onSaveContactClicked} disabled={!termsChecked}>
              Send Message
            </Button>
          </form>
        </div>
      </div>
    </section>
    </>
  );
}

export default XContactForm;