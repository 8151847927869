import PropTypes from "prop-types";
import { Card, 
  CardBody, 
  CardHeader,
  CardFooter,
  Tooltip,
  Avatar, 
  Typography } from "@material-tailwind/react";

export function TeamCard({ img, name, position, socials }) {
  return (
    <Card color="transparent" shadow={false} className="w-96">
      <CardHeader floated={false} className="h-80">
        <img src={img} alt={name} />
      </CardHeader>
      <CardBody className="text-center">
        <Typography variant="h4" color="blue-gray" className="mb-2">
          {name}
        </Typography>
        {position && (
          <Typography color="blue-gray" className="font-medium" textGradient>
            {position}
          </Typography>
        )}
      </CardBody>
      <CardFooter className="flex justify-center gap-7 pt-2">
        {socials.map( record => {
            if (record.name === 'facebook') {
              return (
                <Tooltip content="Like">
                  <Typography as="a" target="_blank" rel="noreferrer" href={record.location} variant="lead" color={record.color} textGradient>
                    <i className="fab fa-facebook" />
                  </Typography>
                </Tooltip>
              )
            } else if (record.name === 'twitter') {
              return (
                <Tooltip content="Follow">
                  <Typography as="a" target="_blank" rel="noreferrer" href={record.location} variant="lead" color={record.color} textGradient>
                    <i className="fab fa-twitter" />
                  </Typography>
                </Tooltip>
              )              
            } else if (record.name === 'instagram') {
              return (
                <Tooltip content="Follow">
                  <Typography as="a" target="_blank" rel="noreferrer" href={record.location} variant="lead" color={record.color} textGradient>
                    <i className="fab fa-instagram" />
                  </Typography>
                </Tooltip>
              )              
            } else {
              return (
                <Tooltip content="Follow">
                  <Typography as="a" target="_blank" rel="noreferrer" href={record.location} variant="lead" color={record.color} textGradient>
                    <i className="fab fa-linkedin" />
                  </Typography>
                </Tooltip>
              )
            }
        })}     
      </CardFooter>
    </Card>
  );
}

TeamCard.defaultProps = {
  position: "",
  socials: null,
};

TeamCard.propTypes = {
  img: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string,
  socials: PropTypes.node,
};

TeamCard.displayName = "/src/widgets/layout/team-card.jsx";

export default TeamCard;