import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
  Button,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { PageTitle } from "../../widgets/layout/page-title";
import { Footer } from "../../widgets/layout/footer";
import { FeatureCard } from "../../widgets/cards/feature-card";
import { GridCard } from "../../widgets/cards/grid-card";
import { TeamCard } from "../../widgets/cards/team-card";
import { featuresData, teamData, contactData } from "../../data";

// import ServicesImage from "../../assets/images/teams/services2.jpeg";
import ServicesImage from "../../assets/images/patterns/build_together.jpeg";

import ConnectPartners from "../../assets/images/teams/connect.jpeg";
// import BuildTogether from "../../assets/images/patterns/build_together.jpeg";

// import CarouselComponent from "../../components/CarouselComponent";
// import { successStoriesData } from "../../data/success-slides-data.js";

import XContactForm from "./XContactForm";


export function HomePage() {

  const [open, setOpen] = useState(0);
  const [alwaysOpen, setAlwaysOpen] = useState(true);

  const handleAlwaysOpen = () => setAlwaysOpen((cur) => !cur);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);


  return (
    <>
      <Card className="content-center">
        {/* this is for scrolling and alignment */}
        <div className="relative flex content-center items-center justify-center pt-16 pb-32"> {/* pt-48 pb-16 */}
          {/* The next 2 divs are for background coloring - consistency across pages */}
          <div className="absolute top-0 h-full w-full bg-cover bg-center bg-blue-100" />
          <div className="absolute top-0 h-full w-full bg-black/60 bg-cover bg-center" />
          <div className="max-w-8xl container relative mx-auto">
            <div className="flex flex-wrap items-center">
              <div className="ml-auto mr-auto w-full px-4 text-center">
                <Typography variant="h2" color="white">
                  Partners in your success
                </Typography>
                <Card className="mt-6 w-full">
                  <CardBody>
                    <Typography
                      variant="h1"
                      color="white"
                      className="mb-6 font-black"
                    >
                    </Typography>
                    <Typography variant="h3" color="black" className="opacity-100" >
                      Revolutionizing the way <b style={{color:'orange'}}>small</b> to <b style={{color:'orange'}}>medium</b> sized tech companies find and hire <b style={{color:'orange'}}>reliable</b> service providers for their project needs.  
                    </Typography>
                    <Typography variant="lead" color="blue-gray" className="opacity-100 pt-5">
                      We are a trusted and reliable resource providing clients unparalleled service by connecting 
                      top rated vendors and clients.
                    </Typography>
                    <Typography variant="lead" color="blue-gray" className="opacity-100 pt-5">
                      <b>We facilitate seamless interactions for our partners to make informed decisions. </b>
                    </Typography> 
                  </CardBody>              
                  <CardFooter className="pt-0 d-flex">
                    <img 
                      className="rounded-sm h-100 mx-auto"
                      src={ConnectPartners}
                      alt="partners" />
                  </CardFooter>
                </Card>
              </div>
            </div>
          </div>
        </div>
        <section className=" bg-white px-4 pb-20 pt-4">
          <GridCard style={{float : 'left', paddingRight : '5px'}} 
                key="3434"
                color="purple"
                title="Our Services"
                icon=""
          />

          <div className="container mx-auto bg-black/60 bg-cover bg-center bg-gray-100">
            <div className="mt-32 flex flex-wrap items-center">
              <div className="mx-auto -mt-8 w-full px-4 md:w-5/12">
                <div className="mb-6 inline-flex h-16 w-16 items-center justify-center rounded-full bg-blue-gray-900 p-2 text-center shadow-lg">
                  <XCircleIcon className="h-8 w-8 text-white " />
                </div>

                <Accordion open={alwaysOpen}>
                  <AccordionHeader onClick={handleAlwaysOpen}>Why work with XConnections?</AccordionHeader>
                  <AccordionBody>
                    <Typography className="mb-8 font-normal text-blue-gray-500">
                      Platform built with a focus on specific needs of clients and specialized skillset of the vendors.
                    </Typography>
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 1}>
                  <AccordionHeader onClick={() => handleOpen(1)}>
                    Our platform
                  </AccordionHeader>
                  <AccordionBody>
                    <Typography className="mb-8 font-normal text-blue-gray-500">
                      Online platform for matching clients and vendors and document their needs, interactions and monitor progress.
                    </Typography>
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 2}>
                  <AccordionHeader onClick={() => handleOpen(2)}>
                    What do we offer?
                  </AccordionHeader>
                  <AccordionBody>
                    <Typography className="mb-8 font-normal text-blue-gray-500">
                      Guidance, oversight and project management by experienced independent staff.
                    </Typography>
                  </AccordionBody>
                </Accordion>
                <div className="mt-32 flex flex-wrap items-center">
                </div>
                <a href="./readme">
                <Button variant="filled">read more</Button>
                </a>
              </div>
              <div className="mx-auto mt-24 flex w-full justify-center px-4 md:w-4/12 lg:mt-0">
                <Card className="shadow-lg border shadow-gray-500/10 rounded-lg">
                  <CardHeader floated={false} className="relative h-56">
                    <img
                      alt="Card"
                      src={ServicesImage}
                      className="h-full w-full"
                    />
                  </CardHeader>
                  <CardBody>
                    <Typography variant="small" color="blue-gray" className="font-normal">Services</Typography>
                    <Typography
                      variant="h5"
                      color="blue-gray"
                      className="mb-3 mt-2 font-bold"
                    >
                      Our Services
                    </Typography>
                    <Typography className="font-normal text-blue-gray-500">
                      By connecting clients with top-rated vendors, we facilitate
                      seamless interactions and empower clients to make informed 
                      and confident decisions when choosing vendor providers.
                    </Typography>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </section>

        {/* <CarouselComponent data={successStoriesData}/> */}

        <section className="px-4 pt-20 pb-10">
          <div className="container mx-auto bg-blue-gray-50">
            <Card className="mt-6 w-full">
              <CardBody>
                <PageTitle section="" heading="Our Team">            
                  <Typography variant="paragraph" color="blue-gray" className="font-normal">
                    Our company’s commitment lies in driving unparalleled customer satisfaction and promoting an unbiased approach to connecting 
                    clients with specialized service vendors. The founding team members
                    have extensive experience in software development, product development, project and vendor management.
                  </Typography>
                </PageTitle>
              </CardBody>
            </Card>
            {/* NOTE: temporarily remove the founder section from the front page
            <div className="mt-24 grid grid-cols-2 gap-12 gap-x-24 md:grid-cols-2 xl:grid-cols-2 m-auto">
              {teamData.map(({ img, name, position, socials }) => (
                <TeamCard
                  key={name}
                  img={img}
                  name={name}
                  position={position}
                  socials={socials}
                />
              ))}
            </div> */}
          </div>
        </section>
        <section className="relative bg-white py-2 px-2">
          <div className="container mx-auto ">
              <div className="mx-auto mt-20 mb-24 grid max-w-5xl grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-3">
              {contactData.map(({ title, icon, description }) => (
                <Card
                  key={title}
                  color="white"
                  shadow={false}
                  className="text-center text-blue-gray-900"
                >
                  <div className="mx-auto mb-6 grid h-14 w-14 place-items-center rounded-full bg-blue-gray-900 shadow-lg shadow-gray-500/20">
                    {React.createElement(icon, {
                      className: "w-5 h-5 text-white",
                    })}
                  </div>
                  <Typography variant="h5" color="blue-gray" className="mb-2">
                    {title}
                  </Typography>
                  <Typography className="font-normal text-blue-gray-900">
                    {description}
                  </Typography>
                </Card>
              ))}
            </div>
            <XContactForm />
          </div>
        </section>
        <div className="bg-white">
          <Footer />
        </div>
      </Card>
    </>
  );
}

export default HomePage;