import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_ENDPOINT;

// public data
export const getNewsLetter = async () => {
  const config = {
    url: `${apiServerUrl}newsletter`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

// protected
export const login = async (loginData) => {
  const config = {
    url: `${apiServerUrl}login`,
    data: loginData,
    method: "POST",
    headers: {
      "content-type": "application/json",
      //Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};


// protected
export const postUserData = async (accessToken, type, userData) => {
  const config = {
    url: type === "client" ? `${apiServerUrl}admin/adduser`: `${apiServerUrl}admin/addNewVendor`,
    data: userData,
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getAdminClientData = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}admin/clients`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getAdminVendorData = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}admin/vendors`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};