import Clients from '../../assets/images/teams/clients.jpeg'
import Vendors from '../../assets/images/teams/vendors.jpeg'
import Everyone from '../../assets/images/teams/everyone.jpeg'

export function GridCard({ color, icon, title, description }) {

    return (

        <div class="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16">

            <div class="border-b mb-5 flex justify-between text-sm">
                <div class="text-indigo-600 flex items-center pb-2 pr-2 border-b-2 border-indigo-600 uppercase">
                    <a href="/readme" target="_blank" class="font-semibold inline-block">About Us</a>
                </div>
            </div>

            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">

                {/* <!-- Client Card --> */}
                <div class="rounded overflow-hidden shadow-lg flex flex-col">
                    <div class="relative">
                        <img class="w-full"
                            src={Clients}
                            alt="Clients"
                        />
                        <div
                            class="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25">
                        </div>
                    </div>
                    <div class="px-6 py-4 mb-auto">
                        <div class="font-medium text-lg hover:text-indigo-600 transition duration-500 ease-in-out inline-block mb-2">
                            For Clients
                        </div>
                        <p class="text-gray-500 text-sm">
                            Access to top rated vendors with specialized skill sets and independent oversight from project kickoff to delivery.
                        </p>
                    </div>
                </div>

                {/* <!-- Vendor Card --> */}
                <div class="rounded overflow-hidden shadow-lg flex flex-col">
                    <div class="relative">
                        <img class="w-full"
                            src={Vendors}
                            alt="Vendors"
                        />
                        <div
                            class="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25">
                        </div>
                    </div>
                    <div class="px-6 py-4 mb-auto">
                        <div class="font-medium text-lg hover:text-indigo-600 transition duration-500 ease-in-out inline-block mb-2">
                            For Vendors
                        </div>
                        <p class="text-gray-500 text-sm">
                            Access to a network of clients to promote services offered and credibility to attract more customers.
                        </p>
                    </div>
                </div>

                {/* <!-- Everyone Card --> */}
                <div class="rounded overflow-hidden shadow-lg flex flex-col">
                    <div class="relative">
                        <img class="w-full"
                            src={Everyone}
                            alt="Everyone"
                        />
                        <div
                            class="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0 bg-gray-900 opacity-25">
                        </div>
                    </div>
                    <div class="px-6 py-4 mb-auto">
                        <div class="font-medium text-lg hover:text-indigo-600 transition duration-500 ease-in-out inline-block mb-2">
                            For everyone
                        </div>
                        <p class="text-gray-500 text-sm">
                            Trusted partner with vast experience to provide guidance and support throughout the product lifecycle and offer visibility
                        </p>
                    </div>
                </div>

            </div>

        </div>
    );
}

GridCard.displayName = "/src/widgets/layout/grid-card.jsx";

export default GridCard;