import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Avatar,
} from "@material-tailwind/react";

import { Link } from "react-router-dom";
import DefaultLayout from '../../layouts/DefaultLayout';
import Breadcrumb from '../../components/Breadcrumb';
import { useGetProjectsQuery } from "../../features/projects/projectsApiSlice";

export function ProjectCards() {

  const {
    data: project_datails,
    isLoading,
    isSuccess,
    error
  } = useGetProjectsQuery('projectsTable', {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })

  if (isLoading) {
    return (<p>Loading.....</p>)
  }
  return (
    <DefaultLayout>
      <Breadcrumb pageName='Projects' />
      <div className="grid grid-cols-3 gap-4 place-items-center h-56">
        {project_datails?.map(
          ({ project_id, project_name, client_id, vendor_id, project_desc, start_date, end_date, doc_storage_location, category }, index) => {

            return (
              <div>
                <Card className="w-auto max-w-[24rem]">
                  <CardHeader floated={false} shadow={false} className="rounded-none">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-medium"
                    >
                      {category}
                    </Typography>
                  </CardHeader>
                  <CardBody>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-medium uppercase"
                    >
                      project
                    </Typography>
                    <Typography
                      variant="h4"
                      color="blue-gray"
                      className="mt-1 mb-2"
                    >
                      {project_name}
                    </Typography>
                    <Typography color="gray" className="font-normal">
                      {project_desc}
                    </Typography>
                  </CardBody>
                  <CardFooter className="flex items-center pt-1">
                    <Avatar
                      src={`https://www.material-tailwind.com/img/avatar6.jpg`}
                      alt="David Suozza"
                    />
                    <div className="ml-4">
                      <Typography color="blue-gray" className="font-medium">
                        David Suozza
                      </Typography>
                      <Typography variant="small" color="gray">
                        Started on: <br></br>{start_date}
                      </Typography>
                    </div>
                    <Link to={`/dashboard/projectdetails/${project_id}`}>
                      {/*<a href="/dashboard/projectdetails/company/1/project/1" className="inline-block">*/}
                      <Button size="sm" variant="text" className="flex items-center gap-2">
                        Project Details
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                          className="h-4 w-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                          />
                        </svg>
                      </Button>
                      {/*</a>*/}
                    </Link>
                  </CardFooter>
                </Card>
              </div>
            );
          }
        )}

      </div>

    </DefaultLayout>
  );
}

export default ProjectCards;