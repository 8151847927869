export const projectsTableData = [
    {
      img: "/img/logo-xd.svg",
      name: "Material XD Version",
      members: [
        { img: "/img/team-1.jpeg", name: "Team Member 1" },
        { img: "/img/team-2.jpeg", name: "Team Member 2" },
        { img: "/img/team-3.jpeg", name: "Team Member 3" },
        { img: "/img/team-4.jpeg", name: "Team Member 4" },
      ],
      budget: "$14,000",
      completion: 60,
    },
    {
      img: "/img/logo-atlassian.svg",
      name: "Add Progress Track",
      members: [
        { img: "/img/team-2.jpeg", name: "Team Member 2" },
        { img: "/img/team-4.jpeg", name: "Team Member 4" },
      ],
      budget: "$3,000",
      completion: 10,
    },
    {
      img: "/img/logo-slack.svg",
      name: "Fix Platform Errors",
      members: [
        { img: "/img/team-3.jpeg", name: "Team Member 3" },
        { img: "/img/team-1.jpeg", name: "Team Member 1" },
      ],
      budget: "Not set",
      completion: 100,
    },
    {
      img: "/img/logo-spotify.svg",
      name: "Launch our Mobile App",
      members: [
        { img: "/img/team-4.jpeg", name: "Team Member 4" },
        { img: "/img/team-3.jpeg", name: "Team Member 3" },
        { img: "/img/team-2.jpeg", name: "Team Member 2" },
        { img: "/img/team-1.jpeg", name: "Team Member 1" },
      ],
      budget: "$20,500",
      completion: 100,
    },
    {
      img: "/img/logo-jira.svg",
      name: "Add the New Pricing Page",
      members: [{ img: "/img/team-4.jpeg", name: "Team Member 4" }],
      budget: "$500",
      completion: 25,
    },
    {
      img: "/img/logo-invision.svg",
      name: "Redesign New Online Shop",
      members: [
        { img: "/img/team-1.jpeg", name: "Team Member 1" },
        { img: "/img/team-4.jpeg", name: "Team Member 4" },
      ],
      budget: "$2,000",
      completion: 40,
    },
  ];
  
export default projectsTableData;