import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice"

const projectsAdapter = createEntityAdapter({})

const initialState = projectsAdapter.getInitialState()

export const projectApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProject: builder.query({
            query: () => ({
                url: '/admin/projects/{id}'
            })
        })
    })
})

export const projectsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getProjects: builder.query({
            // note: an optional `queryFn` may be used in place of `query`
            query: () => ({
                url: '/admin/projects',
                header: {'content-type': 'application/json', 
                    'Access-Control-Allow-Origin': '*', 
                    'Access-Control-Allow-Credentials': "true"
                },
                validateStatus: (response, result) => {
                    return response.status === 200
                },
            }),
            // Keep unused data for 5 seconds - cache in RTK query - default is 60 seconds
            keepUnusedDataFor: 5,
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: response => {
                 return response;
            },
            // Pick out errors and prevent nested properties in a hook or selector
            transformErrorResponse: (response, meta, arg) => response.status,
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Project', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Project', id }))
                    ]
                } else return [{ type: 'Project', id: 'LIST' }]
            }
        }),
        addNewProject: builder.mutation({
            query: initialProjectData => ({
                url: '/admin/projects',
                    method: 'POST',
                    body: {
                        ...initialProjectData,
                    }
            }),
            transformResponse: response => {
                 return response;
            },
            invalidatesTags: [
                { type: 'Project', id: "LIST" }
            ]
        }),
        updateProject: builder.mutation({
            query: initialProjectData => ({
                url: '/admin/projects',
                method: 'PATCH',
                body: {
                    ...initialProjectData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Project', id: arg.id }
            ]
        }),
        deleteProject: builder.mutation({
            query: ({ id }) => ({
                url: `/admin/projects`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Project', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetProjectsQuery,
    useAddNewProjectMutation,
    useUpdateProjectMutation,
    useDeleteProjectMutation,
} = projectsApiSlice

// returns the query result object
export const selectProjectsResult = projectsApiSlice.endpoints.getProjects.select()

// creates memoized selector
const selectProjectsData = createSelector(
    selectProjectsResult,
    projectsResult => projectsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllProjects,
    selectById: selectProjectById,
    selectIds: selectProjectIds
    // Pass in a selector that returns the Projects slice of state
} = projectsAdapter.getSelectors(state => selectProjectsData(state) ?? initialState)