import {
  Typography,
  Card,
  CardBody
} from "@material-tailwind/react";

import { Footer } from "../../widgets/layout/footer";


const ReadMePage = () => {

  return (

    <>
      <div className="relative flex content-center items-center justify-center pt-16 pb-32"> {/* pt-48 pb-16 */}
        {/* The next 2 divs are for background coloring - consistency across pages */}
        <div className="absolute top-0 h-full w-full bg-cover bg-center bg-blue-100" />
        <div className="absolute top-0 h-full w-full bg-black/60 bg-cover bg-center" />
        <div className="max-w-8xl container relative mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="ml-auto mr-auto w-full px-4 text-left">
              <Card className="mt-6 w-full">
                <CardBody>
                  <Typography
                    color="white"
                    className="mb-6 text-black"
                  >
                    <section className="mb-6">
                      <h2 className="font-semibold mb-2 text-xl">About Us</h2>
                      <p>
                        Our commitment to unparalleled service excellence
                        ensures that clients receive tailored solutions to meet their specific needs.
                        We prioritize transparency, integrity, and innovation in all our interactions,
                        fostering long-term, mutually beneficial relationships.
                        With XConnections, tech companies can discover, evaluate, and engage with the right vendors to drive growth
                        and success. Join us in reshaping the landscape of tech vendor sourcing and collaboration.
                      </p>
                    </section>

                    <section className="mb-6">
                      <h2 className="font-semibold mb-2 text-xl">Why XConnections?</h2>
                      <p>
                        At XConnections, we understand the unique challenges businesses face when navigating the landscape between independent freelancers and large consulting firms. These gaps often manifest in projects or engagements that require specialized oversight, strategic vision, and expert guidance to shape the future of your company. Our mission is to fill these gaps by providing comprehensive, tailored solutions that ensure your projects are executed flawlessly from start to finish.
                      </p><br />
                      <p>
                        We believe in being more than just a service provider; we aim to be your trusted partners in every step of your journey. From the initial stages of simplifying your project requirements to identifying and securing the best partner to collaborate with, our team is dedicated to making the process as seamless as possible. Our extensive network and deep industry knowledge allow us to connect you with top-tier vendors who align perfectly with your project needs.
                      </p><br />
                      <p>
                        One of the key advantages of working with XConnections is our commitment to providing ongoing project management support. We understand that effective communication and coordination are critical to the success of any project. Therefore, we act as the bridge between you and your vendors, ensuring that all parties are aligned and working towards the common goal of project success. Our project managers are seasoned professionals who bring a wealth of experience and expertise to the table, ensuring that your projects are delivered on time, within scope, and on budget.
                      </p><br />
                      <p>
                        Our approach allows you to focus on what you do best—growing your business. We handle the complexities of vendor engagement, project oversight, and administrative tasks, freeing you up to concentrate on strategic initiatives and core business operations. At XConnections, we pride ourselves on being an extension of your team, committed to your success. Our expert team will work tirelessly to ensure that every project we undertake is a resounding success, providing you with the peace of mind that your business is in capable hands.
                      </p><br />
                      <p>
                        Choose XConnections for your next project and experience the difference that dedicated, expert oversight can make. Let us be the partners you need to drive your business forward, while we handle the rest.
                      </p>
                    </section>
                  </Typography>

                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>

  );
};

export default ReadMePage;
