import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Navbar as MTNavbar,
  Collapse,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import XConnectionsLogo from '../../assets/images/logos/x_connections_logo3.png'
import useAuth from "../../hooks/useAuth";

export function Navbar({ routes, action }) {

  const { username, status, isAdmin } = useAuth();
  console.log("isAdmin="+isAdmin);

  const { isLoggedIn } = useSelector(state => state.auth);
  console.log("isLoggedIn="+isLoggedIn);
  
  
  const [openNav, setOpenNav] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      {routes.map(({ layout, pages }) =>
          layout === "home" && pages.map(({ name, path, icon, navVisibility, protectedPage }) => 
          (
            <Typography
              key={name}
              as="li"
              variant="lead"
              color="blue-gray"
              className="capitalize" 
            >
            {navVisibility || isLoggedIn ? (
                <Link to={path} className="flex items-center gap-1 p-1 font-medium">
                  <b>{name}</b>
                </Link>
              ) : (null)
            }
          </Typography>
        )
      ))}
    </ul>
  );

  return (
    <MTNavbar className="p-3">
      <div className="container mx-auto flex items-center justify-between text-blue-gray-900">
        <Link to="/">
          <Typography
            variant="small"
            className="mr-4 ml-2 cursor-pointer py-1.5 font-bold"
          >
            <img 
              src={XConnectionsLogo} 
              alt='XConnectionsLogo' 
              width={245} 
              height={48} />
          </Typography>
        </Link>
        <div className="hidden lg:block">{navList}</div>
        {React.cloneElement(action, {
          className: "hidden lg:inline-block",
        })}
        <IconButton
          variant="text"
          size="sm"
          className="ml-auto text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon strokeWidth={2} className="h-6 w-6" />
          ) : (
            <Bars3Icon strokeWidth={2} className="h-6 w-6" />
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <div className="container mx-auto">
          {navList}
          {React.cloneElement(action, {
            className: "w-full block lg:hidden",
          })}
        </div>
      </Collapse>
    </MTNavbar>
  );
}

Navbar.defaultProps = {
  action: (
    <div className="flex w-max items-center gap-4">
    <a
      alt="home"
      href="https://www.xconnections.com"
      rel="noreferrer"
      target="_blank"
    >        
    </a>
    <div className="flex w-max items-center gap-4">
    <a href="login">
      <Button>
        login
      </Button>
    </a>
    </div>
    </div>

  ),
};

Navbar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.node,
};

Navbar.displayName = "/src/widgets/layout/navbar.jsx";

export default Navbar;